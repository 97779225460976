import React from "react"
import clsx from "clsx"
import types from "prop-types"
import { useSelector } from "react-redux"
import {
  groups as getGroups,
  users as getUsers,
  activeDepartments as getDepartments,
} from "reduxSlices/formSubmissionSlice"
import GroupAndUserAndDepartmentSelect from "./GroupAndUserAndDepartmentSelect"

const unselectedList = (list, selectedList) => {
  const selectedIds = selectedList.map((entry) => entry.grantee?.id || entry.id)
  return list?.filter((item) => !selectedIds.includes(item.id)) ?? []
}

const GroupAndUserAndDepartmentSearch = ({
  className, onSearchSelected, selectedGroups, selectedUsers, selectedDepartments,
}) => {
  const groups = useSelector(getGroups)
  const users = useSelector(getUsers)
  const departments = useSelector(getDepartments)

  return (
    <GroupAndUserAndDepartmentSelect
      className={clsx(className, "text-left")}
      users={unselectedList(users, selectedUsers)}
      groups={unselectedList(groups, selectedGroups)}
      departments={unselectedList(departments, selectedDepartments)}
      onChange={onSearchSelected}
    />
  )
}

GroupAndUserAndDepartmentSearch.defaultProps = {
  className: "",
  selectedGroups: [],
  selectedUsers: [],
  selectedDepartments: [],
}

GroupAndUserAndDepartmentSearch.propTypes = {
  className: types.string,
  onSearchSelected: types.func.isRequired,
  selectedGroups: types.arrayOf(types.object),
  selectedUsers: types.arrayOf(types.object),
  selectedDepartments: types.arrayOf(types.object),
}

export default GroupAndUserAndDepartmentSearch
