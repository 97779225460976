import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getIsUploading, getFilteredGroupedAttachments } from "reduxSlices/formSubmissionAttachmentsGroupingSlice"
import Modal from "shared/Modal"
import EmptyAttachments from "./EmptyAttachments"
import AttachmentGroupingList from "./AttachmentGroupingList"
import AttachmentUploadInstructions from "./AttachmentUploadInstructions"
import AttachmentDropzone from "./AttachmentDropzone"
import UploadingFormSubmissionAttachments from "./UploadingFormSubmissionAttachments"

const AttachmentsList = ({ attachments }) => {
  const attachmentGroups = useSelector(getFilteredGroupedAttachments)
  const isUploading = useSelector(getIsUploading)

  return (
    <>
      { attachments.length === 0
        ? (
          <AttachmentDropzone>
            <EmptyAttachments />
          </AttachmentDropzone>
        )
        : (
          <AttachmentDropzone>
            <AttachmentGroupingList className="mb-16" attachmentGroups={attachmentGroups} />
            <AttachmentUploadInstructions className="md:mb-16 text-center" />
          </AttachmentDropzone>
        )}
      <Modal isOpen={isUploading}>
        <UploadingFormSubmissionAttachments />
      </Modal>
    </>
  )
}

AttachmentsList.propTypes = {
  attachments: types.arrayOf(types.object).isRequired,
}

export default AttachmentsList
