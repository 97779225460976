import React, { useCallback, useEffect, useMemo } from "react"
import types from "prop-types"
import clsx from "clsx"
import { isEqual, sortBy } from "lodash-es"
import { useSelector } from "react-redux"
import {
  recordActive,
  valueMatches,
  getActiveOrMatchingFacilities,
  getDepartmentFacilityMapping,
  getFacilityDepartments,
} from "reduxSlices/formBuilderSlice"
import InactiveOptionsItemRenderer from "shared/selects/InactiveOptionsItemRenderer"
import { ListSelect } from "shared/selects"

const itemToOption = (list) => list.map((item) => (
  { label: item.name, value: item.id, active: item.active }
))

const LocationIsDepartmentMatchValueInput = ({
  className,
  isFullWidth,
  onChange,
  value,
}) => {
  const departmentFacilityMapping = useSelector(getDepartmentFacilityMapping, isEqual)
  const facilities = useSelector(getActiveOrMatchingFacilities(departmentFacilityMapping[value]), isEqual)
  const facilityDepartments = useSelector(getFacilityDepartments, isEqual)

  const sortedFacilities = useMemo(() => sortBy(facilities, "name"), [facilities])

  const sortedDepartments = useMemo(() => {
    if (!value) return []

    const facilityId = departmentFacilityMapping[value]
    const departments = facilityDepartments[facilityId]?.filter((department) => (recordActive(department) || valueMatches(department.id, value)))
    return sortBy(departments, "name")
  }, [departmentFacilityMapping, facilityDepartments, value])

  const selectFacility = useCallback((facilityId) => {
    const prevFacilityId = departmentFacilityMapping[value]

    if (facilityId !== prevFacilityId) {
      onChange(sortBy(facilityDepartments[facilityId], "name")[0].id)
    }
  }, [departmentFacilityMapping, facilityDepartments, onChange, value])

  useEffect(() => {
    if (!value) selectFacility(sortedFacilities[0].id)
  }, [selectFacility, sortedFacilities, value])

  return (
    <>
      <ListSelect
        backspaceDelete={false}
        className={clsx(className, "mb-2")}
        isFullWidth={isFullWidth}
        itemRenderer={InactiveOptionsItemRenderer}
        onChange={selectFacility}
        options={sortedFacilities}
        optionsFromList={itemToOption}
        required
        value={departmentFacilityMapping[value]}
      />
      <ListSelect
        backspaceDelete={false}
        className={className}
        isFullWidth={isFullWidth}
        itemRenderer={InactiveOptionsItemRenderer}
        onChange={onChange}
        options={sortedDepartments}
        optionsFromList={itemToOption}
        required
        value={value}
      />
    </>
  )
}

LocationIsDepartmentMatchValueInput.defaultProps = {
  className: "",
  value: null,
}

LocationIsDepartmentMatchValueInput.propTypes = {
  className: types.string,
  isFullWidth: types.bool.isRequired,
  onChange: types.func.isRequired,
  value: types.number,
}

export default LocationIsDepartmentMatchValueInput
