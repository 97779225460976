/* eslint-disable react/display-name */
import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { getInitialActiveIndexFromQueryParams } from "utils/tabHelper"
import { Tabs, Tab } from "shared/tabs"
import { submissionPermissions as getSubmissionPermissions } from "reduxSlices/formSubmissionSlice"
import ActivitiesContent from "./ActivitiesContent"
import SubmissionContent from "./SubmissionContent"
import SubmissionAccess from "./SubmissionAccess"
import AuditTrailContent from "./AuditTrailContent"
import AttachmentsContent from "./AttachmentsContent"
import AttachmentsCountBadge from "./AttachmentsContent/Content/AttachmentsCountBadge"
import SupplementalContent from "./SupplementalContent"

const SUBMISSION_TAB = {
  title: "Submission",
  TabContent: SubmissionContent,
}

const ACTIVITIES_TAB = {
  title: "Activities",
  TabContent: ActivitiesContent,
}

const SUBMISSION_ACCESS_TAB = {
  title: "Submission Access",
  TabContent: SubmissionAccess,
}

const ATTACHMENTS_TAB = {
  title: "Attachments",
  NotificationBadge: AttachmentsCountBadge,
  TabContent: AttachmentsContent,
}

const SUPPLEMENTAL_TAB = {
  title: "Supplemental",
  TabContent: SupplementalContent,
}

const AUDIT_TRAIL_TAB = {
  title: "Audit Trail",
  TabContent: AuditTrailContent,
}

const DEFAULT_FORM_SUBMISSION_TABS = [
  SUBMISSION_TAB,
  ACTIVITIES_TAB,
  SUPPLEMENTAL_TAB,
]

// Tab order:
//   Submission | Activities | Submission Access (if visible) | Attachments (if visible) | Supplemental | Audit Trail (if Visible)
const listVisibleTabs = ({ canManageSubmissionGrants, canViewAuditTrail, canViewUploadDownloadAttachments }) => {
  const tabs = [...DEFAULT_FORM_SUBMISSION_TABS]

  if (canViewUploadDownloadAttachments) tabs.splice(2, 0, ATTACHMENTS_TAB)

  // Submission access tab should be the third tab, if visible
  if (canManageSubmissionGrants) tabs.splice(2, 0, SUBMISSION_ACCESS_TAB)

  // Audit trail tab should be the last tab, if visible
  if (canViewAuditTrail) tabs.push(AUDIT_TRAIL_TAB)

  return tabs
}

const FormSubmissionTabs = () => {
  const submissionPermissions = useSelector(getSubmissionPermissions)

  const visibleTabs = useMemo(() => (
    listVisibleTabs(submissionPermissions)
  ), [submissionPermissions])

  return (
    <Tabs
      initialActiveIndex={getInitialActiveIndexFromQueryParams(visibleTabs)}
    >
      {
        visibleTabs.map(({ title, TabContent, NotificationBadge }) => (
          <Tab key={title} title={title} NotificationBadge={NotificationBadge}>
            <TabContent />
          </Tab>
        ))
      }
    </Tabs>

  )
}

export default FormSubmissionTabs
